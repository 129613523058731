import React, { useCallback, useEffect, useRef, useState } from "react"
import Modal from "@jobintrans/base-ui.components.molecules.modal"

import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { CATEGORY_ARRAY, LICENCES_ARRAY, OTHERPOSITIONS_ARRAY } from "@jobintrans/base-ui.data"
import Select from "@jobintrans/base-ui.components.atoms.select"
import { H3, P } from "@jobintrans/base-ui.components.atoms.typography"
import { InputAutocomplete } from "@jobintrans/base-ui.components.atoms.input"
import { GeolocationService, LocalStorageService } from "@jobintrans/base-ui.services.api-service"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { GeolocationAutocompleteType } from "@jobintrans/base-ui.types.api-integrations"
import { useTranslation } from "react-i18next"
import DatePicker from "@jobintrans/base-ui.components.atoms.date-picker"
import { IconBox } from "@jobintrans/base-ui.components.atoms.icon"
import dayjs from "dayjs"
import { SentryService } from "services/SentryService"
import { AddOfferHelperText, ChangeLanguageComponent } from "components/shared/ModalHelpers"
import { logout } from "store/auth"
import { RootState } from "store/store"
import { locales } from "utils/lang.utils"
import { localeToIcon } from "utils/flags.utils"

const AddOfferBasicInfo = ({ state, onBack, onChange }: any) => {
  const { t, i18n } = useTranslation(["add-offer", "common", "data"])
  const dispatch = useDispatch()
  const { profile } = useSelector((state: RootState) => state.profile)
  const {
    getValues,
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm<{
    category: string
    subcategory: string
    startDate: string
    endDate: string
    language: string
    address: GeolocationAutocompleteType | null
    endAddress: GeolocationAutocompleteType | null
  }>({
    defaultValues: {
      category: state.type === "jumper" ? "drivers" : state.category,
      subcategory: state.subcategory,
      address: state.address,
      endAddress: state.endAddress,
      startDate: state.startDate,
      endDate: state.endDate,
      language: state.language ?? i18n.language,
    },
  })

  const timeoutId = useRef<number | null>()
  const timeoutEndId = useRef<number | null>()

  const [addressOptions, setAddressOptions] = useState<GeolocationAutocompleteType[]>(
    state?.address ? [state?.address] : [],
  )
  const [endAddressOptions, setEndAddressOptions] = useState<GeolocationAutocompleteType[]>(
    state?.endAddress ? [state?.endAddress] : [],
  )

  const [addressInput, setAddressInput] = useState<string | undefined>(
    state?.address ? state?.address.name : undefined,
  )
  const [endAddressInput, setEndAddressInput] = useState<string | undefined>(
    state?.endAddress ? state?.endAddress.name : undefined,
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [loadingEndAddress, setLoadingEndAddress] = useState<boolean>(false)

  const category = watch("category")
  const address = watch("address")
  const endAddress = watch("endAddress")

  const isJumper = state.type === "jumper"

  const onSubmit = useCallback(
    async (el: any) => {
      onChange(
        el.category,
        el.subcategory,
        el.address,
        isJumper ? el.endAddress : null,
        isJumper ? el.startDate : null,
        isJumper ? el.endDate : null,
        el.language,
      )
    },
    [onChange],
  )

  const searchAddressAutocomplete = useCallback(
    async (
      val: string,
      onSubmit: (e: GeolocationAutocompleteType[]) => void,
      setLoading: (e: boolean) => void,
    ) => {
      try {
        setLoading(true)
        const res = await GeolocationService.autocomplete(val)
        onSubmit(res)
      } catch (e) {
        SentryService.error("[ERROR GeolocationService.autocomplete]:", e)
        onSubmit([])
      } finally {
        setLoading(false)
      }
    },
    [],
  )

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    // @ts-ignore
    timeoutId.current = setTimeout(() => {
      if (addressInput && addressInput.length > 3) {
        searchAddressAutocomplete(addressInput, setAddressOptions, setLoading)
      }
    }, 1000)
  }, [addressInput])

  useEffect(() => {
    if (timeoutEndId.current) {
      clearTimeout(timeoutEndId.current)
    }

    // @ts-ignore
    timeoutEndId.current = setTimeout(() => {
      if (endAddressInput && endAddressInput.length > 3) {
        searchAddressAutocomplete(endAddressInput, setEndAddressOptions, setLoadingEndAddress)
      }
    }, 1000)
  }, [endAddressInput])

  const handleLogOut = useCallback(() => {
    LocalStorageService.removeObject("token")
    dispatch(logout())
  }, [])

  const onChangeDatePicker = useCallback(({ name, value }: any) => {
    setValue(name, value)
  }, [])

  return (
    <Modal
      show={true}
      icon="plus-circle"
      title={t("basic-info.title")}
      text={t("basic-info.text")}
      maxWidth={state.type === "jumper" ? "900px" : "500px"}
      showBackground={false}
      mobileFullScreen={true}
      helperElement={
        <ChangeLanguageComponent>
          <AddOfferHelperText />
        </ChangeLanguageComponent>
      }
      helperButton={{
        text: t("logout", { ns: "common" }),
        onClick: handleLogOut,
      }}
      bottomElement={
        <Row justifyContent="flex-end">
          <Button type="secondary" onClick={onBack} marginRight="S">
            {t("back", { ns: "common" })}
          </Button>
          <Button onClick={handleSubmit(onSubmit)}>{t("next", { ns: "common" })}</Button>
        </Row>
      }
    >
      <>
        <StyledGrid marginBottom="M">
          <Select
            name="category"
            label={`${t("offer.category", { ns: "form" })}*`}
            options={CATEGORY_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])}
            error={errors.category}
            value={watch("category")}
            disabled={state.type === "jumper"}
            rhf={register("category", {
              required: t("required", { ns: "form" }),
            })}
            marginBottom="S"
          />
          <Select
            name="subCategory"
            label={`${t("offer.subcategory", { ns: "form" })}*`}
            options={
              category === "drivers"
                ? LICENCES_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])
                : OTHERPOSITIONS_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])
            }
            disabled={!category}
            error={errors.subcategory}
            value={watch("subcategory")}
            rhf={register("subcategory", {
              required: t("required", { ns: "form" }),
            })}
          />
        </StyledGrid>
        <Box marginBottom="M">
          {state.type === "jumper" ? (
            <>
              <H3 marginBottom="XS">
                {t("basic-info.jumper-additional-info.title", { ns: "add-offer" })}
              </H3>
              <P small>{t("basic-info.jumper-additional-info.text", { ns: "add-offer" })}</P>
              <Box>
                <JumperTargetWrapper>
                  <JumperTargetContent
                    background="background"
                    padding="S"
                    borderRadius="borderRadius"
                  >
                    <Box>
                      <DatePicker
                        name="startDate"
                        label={`${t("startDate", { ns: "form" })}*`}
                        datePickerProps={{
                          disablePast: true,
                          maxDate: watch("endDate") ? dayjs(watch("endDate")) : null,
                        }}
                        value={watch("startDate")}
                        error={errors.startDate}
                        onChange={onChangeDatePicker}
                        rhf={register("startDate", {
                          required: t("required", { ns: "form" }),
                        })}
                        lang={i18n.language}
                      />
                    </Box>
                    <Box marginTop="S">
                      <Controller
                        name="address"
                        control={control}
                        rules={{
                          required: t("required", { ns: "form" }),
                        }}
                        render={({ field }) => (
                          <InputAutocomplete
                            label={`${t("startAddress", { ns: "form" })}*`}
                            error={errors.address}
                            inputValue={addressInput}
                            onInputChange={setAddressInput}
                            noOptionsText={t("not-found", { ns: "form" })}
                            loading={loading}
                            options={addressOptions.map(el => {
                              return {
                                label: el.name,
                                ...el,
                              }
                            })}
                            {...field}
                          />
                        )}
                      />
                    </Box>
                  </JumperTargetContent>
                  <IconBox icon="arrow-right" color="secondary" background="background" />
                  <JumperTargetContent
                    background="background"
                    padding="S"
                    borderRadius="borderRadius"
                  >
                    <Box>
                      <DatePicker
                        name="endDate"
                        label={`${t("endDate", { ns: "form" })}*`}
                        datePickerProps={{
                          disablePast: true,
                          minDate: watch("startDate") ? dayjs(watch("startDate")) : null,
                        }}
                        value={watch("endDate")}
                        error={errors.endDate}
                        rhf={register("endDate", {
                          required: t("required", { ns: "form" }),
                        })}
                        lang={i18n.language}
                      />
                    </Box>
                    <Box marginTop="S">
                      <Controller
                        name="endAddress"
                        control={control}
                        rules={{
                          required: t("required", { ns: "form" }),
                        }}
                        render={({ field }) => (
                          <InputAutocomplete
                            label={`${t("endAddress", { ns: "form" })}*`}
                            error={errors.endAddress}
                            inputValue={endAddressInput}
                            onInputChange={setEndAddressInput}
                            noOptionsText={t("not-found", { ns: "form" })}
                            loading={loadingEndAddress}
                            options={endAddressOptions.map(el => {
                              return {
                                label: el.name,
                                ...el,
                              }
                            })}
                            {...field}
                          />
                        )}
                      />
                    </Box>
                  </JumperTargetContent>
                </JumperTargetWrapper>
              </Box>
            </>
          ) : (
            <>
              <H3 marginBottom="XS">
                {t("basic-info.additional-info.title", { ns: "add-offer" })}
              </H3>
              <P small>{t("basic-info.additional-info.text", { ns: "add-offer" })}</P>
              <Controller
                name="address"
                control={control}
                rules={{
                  required: t("required", { ns: "form" }),
                }}
                render={({ field }) => (
                  <InputAutocomplete
                    label={`${t("address", { ns: "form" })}*`}
                    error={errors.address}
                    inputValue={addressInput}
                    onInputChange={setAddressInput}
                    noOptionsText={t("not-found", { ns: "form" })}
                    loading={loading}
                    options={addressOptions.map(el => {
                      return {
                        label: el.name,
                        ...el,
                      }
                    })}
                    {...field}
                  />
                )}
              />
            </>
          )}
        </Box>
        <Box>
          <H3 marginBottom="XS">{t("basic-info.language.title", { ns: "add-offer" })}</H3>
          <P small>{t("basic-info.language.text", { ns: "add-offer" })}</P>
          <Select
            name="language"
            options={locales.map(el => [
              `${localeToIcon[el]} ${t(`languages.${el}`, { ns: "common" })}`,
              el,
            ])}
            error={errors.language}
            value={watch("language")}
            rhf={register("language", {
              required: t("required", { ns: "form" }),
            })}
          />
        </Box>
      </>
    </Modal>
  )
}

export default AddOfferBasicInfo

const JumperTargetContent = styled(Box)`
  border-radius: ${({ theme }) => theme.variable.borderRadius};
`

const JumperTargetWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
  align-items: center;

  @media (max-width: 900px) {
    justify-content: center;
    grid-template-columns: 1fr;

    > div:nth-child(2) {
      margin-left: auto;
      margin-right: auto;
      transform: rotate(90deg);
    }
  }
`

const StyledGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`
