import React, { useEffect } from "react"
import "./App.css"
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import styled from "styled-components"
import Spinner from "@jobintrans/base-ui.components.atoms.spinner"
import { LocalStorageService } from "@jobintrans/base-ui.services.api-service"
import ExtendOffer from "pages/Profile/ExtendOffer/ExtendOffer"
import OfferAnalyticsModal from "views/Profile/components/modals/OfferAnalyticsModal"
import { getQuery } from "utils/queryHelper"
import Login from "pages/Login/Login"
import { RootState } from "store/store"
import Register from "pages/Register/Register"
import AddOffer from "pages/Profile/AddOffer/AddOffer"
import EditOffer from "pages/Profile/EditOffer/EditOffer"
import TransactionStatus from "pages/Profile/Transaction/TransactionStatus"
import RegisterProfile from "pages/Register/RegisterProfile"
import PanelLayout from "components/layout/PanelLayout"
import Profile from "pages/Profile/Panel/Profile"
import Offers from "pages/Profile/Panel/Offers"
import AdminProfiles from "pages/Admin/Panel/Profiles"
import AdminOffers from "pages/Admin/Panel/Offers"
import AdminTools from "pages/Admin/Panel/Tools"
import AdminTransactions from "pages/Admin/Panel/Transactions"
import AdminProfileModal from "views/Admin/components/modals/AdminProfileModal"
import OfferApplications from "views/Profile/Offers/OfferApplications/OfferApplications"
import ProfileCompanyView from "views/Profile/Profile/ProfileCompanyView"
import ProfilePersonalView from "views/Profile/Profile/ProfileAddressView"
import ProfileView from "views/Profile/Profile/ProfileView"
import ProfilePasswordView from "views/Profile/Profile/ProfilePasswordView"
import ForgotPassword from "pages/ForgotPassword/ForgotPassword"
import ProfileSurveysView from "views/Profile/Profile/ProfileSurveysView"
import OfferSurveyAnswerModal from "views/Profile/components/modals/OfferSurveyAnswerModal"
import ProfileSettingsView from "views/Profile/Profile/ProfileSettingsView"
import OfferInfo from "pages/Profile/Panel/OfferInfo"
import AdminOfferInfo from "pages/Admin/Panel/OfferInfo"
import OffersList from "views/Admin/Offers/OffersList"
import OfferApplicationAccountModal from "views/Profile/components/modals/OfferApplicationAccountModal"
import AdminOfferAnalyticsModal from "views/Admin/components/modals/AdminOfferAnalyticsModal"
import Analytics from "Analytics"
import ChooseYourPlan from "pages/Profile/ChooseYourPlan/ChooseYourPlan"

function Home() {
  const query = getQuery()
  const { isVerifying, isAuthenticated, user } = useSelector((state: RootState) => state.auth)
  const { profile } = useSelector((state: RootState) => state.profile)

  useEffect(() => {
    if (query.get("code")) {
      LocalStorageService.setObject("code", `${query.get("code")}`)
    }
  }, [])

  if (isVerifying) {
    return (
      <InitWrapper>
        <Spinner />
      </InitWrapper>
    )
  }

  return (
    <InitWrapper>
      <BrowserRouter>
        <Analytics>
          <Routes>
            {!isAuthenticated && !user && (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/reset-password" element={<ForgotPassword />} />
                <Route path="/register" element={<Register />} />
                <Route path="/choose-your-plan" element={<ChooseYourPlan />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </>
            )}
            {isAuthenticated && user && user.status === "create_profile" && (
              <>
                <Route path="/register-profile" element={<RegisterProfile />} />
                <Route path="*" element={<Navigate to="/register-profile" />} />
              </>
            )}
            {isAuthenticated && profile && profile.status === "add_offer" && (
              <>
                <Route path="/add-offer" element={<AddOffer />} />
                <Route path="/add-offer/status" element={<TransactionStatus />} />
                <Route path="*" element={<Navigate to="/add-offer" />} />
              </>
            )}
            {isAuthenticated && profile && profile.status === "active" && (
              <>
                <Route path="/add-offer" element={<AddOffer />} />
                <Route
                  path="/transaction/status/:offerId/:transactionId"
                  element={<TransactionStatus />}
                />
                <Route path="/edit-offer/:id" element={<EditOffer />} />
                <Route path="/extend-offer/:id" element={<ExtendOffer />} />
                <Route path="panel" element={<PanelLayout />}>
                  <Route path="offers" element={<Offers />}>
                    <Route path="m/:offerId/analytics" element={<OfferAnalyticsModal />} />
                  </Route>
                  <Route path="offers/:offerId" element={<OfferInfo />}>
                    <Route path="applications" element={<OfferApplications />}>
                      <Route
                        path="account/:applicationId"
                        element={<OfferApplicationAccountModal />}
                      />
                      <Route path="survey/:applicationId" element={<OfferSurveyAnswerModal />} />
                    </Route>
                  </Route>
                  <Route path="profile" element={<Profile />}>
                    <Route path="" element={<ProfileView />} />
                    <Route path="settings" element={<ProfileSettingsView />} />
                    <Route path="surveys" element={<ProfileSurveysView />} />
                    <Route
                      path="billing"
                      element={
                        profile?.type === "company" ? (
                          <ProfileCompanyView />
                        ) : (
                          <ProfilePersonalView />
                        )
                      }
                    />
                    <Route path="password" element={<ProfilePasswordView />} />
                  </Route>
                  <Route element={<Navigate to="offers" />} />
                </Route>
                <Route path="*" element={<Navigate to="/panel/offers" />} />
              </>
            )}
            {isAuthenticated && user?.role === "ROLE_ADMIN" && (
              <>
                {/*<Route path="/add-offer/:profileId" element={<AdminAddOffers />} />*/}
                <Route path="panel" element={<PanelLayout />}>
                  <Route path="profiles" element={<AdminProfiles />}>
                    <Route path=":profileId" element={<AdminProfileModal />} />
                  </Route>
                  <Route path="offers" element={<AdminOffers />}>
                    <Route path="" element={<OffersList />} />
                    <Route path=":offerId" element={<AdminOfferInfo />}>
                      <Route path="analytics" element={<AdminOfferAnalyticsModal />} />
                    </Route>
                  </Route>
                  <Route path="transactions" element={<AdminTransactions />} />
                  <Route path="tools" element={<AdminTools />} />
                </Route>
                <Route path="*" element={<Navigate to="/panel/profiles" />} />
              </>
            )}
          </Routes>
        </Analytics>
      </BrowserRouter>
    </InitWrapper>
  )
}

export default Home

const InitWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
`
