import React, { useCallback } from "react"
import { LocalStorageService } from "@jobintrans/base-ui.services.api-service"
import { useNavigate } from "react-router-dom"
import AddOfferPlan from "pages/Profile/AddOffer/AddOfferPlan"
import { AnalyticsService } from "services/AnalyticsService"

const ChooseYourPlan = () => {
  const navigate = useNavigate()

  const handleSubmitPlan = useCallback(
    async (plan: string, type: string, period: string, code: string) => {
      const newState = {
        plan,
        type,
        period,
        code,
      }
      AnalyticsService.track("panel_choose_plan", {
        plan,
        type,
        period,
      })

      await LocalStorageService.setObject("choose-plan", JSON.stringify(newState))
      navigate(`/register`)
    },
    [],
  )

  return <AddOfferPlan onChange={handleSubmitPlan} />
}

export default ChooseYourPlan
