import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import Accordion from "@jobintrans/base-ui.components.molecules.accordion"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import Input from "@jobintrans/base-ui.components.atoms.input"
import { EMAIL_REGEX } from "@jobintrans/base-ui.utils.regex"
import Select from "@jobintrans/base-ui.components.atoms.select"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { AdminToolsService } from "@jobintrans/base-ui.services.api-service"
import { SentryService } from "services/SentryService"

const ToolCampaign = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
    formState: { errors, isValid },
  } = useForm()

  const type = watch("type")

  const [response, setResponse] = useState("")
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (el: any) => {
    setLoading(true)
    const { type, email, ...rest } = el
    try {
      await AdminToolsService.campaign(email, type, {
        ...rest,
        language: "pl",
      })
      setResponse("success")
    } catch (e) {
      SentryService.error("[ERROR AdminToolsService.campaign]:", e)
      setResponse("error")
      return
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Accordion title="Dodaj do kampani">
      {response === "error" && (
        <Alert type="error" small marginBottom="S">
          Nie udało się dodać do kampani e-maila, spróbuj podać inny adres e-mail.
        </Alert>
      )}
      {response === "success" && (
        <Alert type="success" small marginBottom="S">
          E-mail został pomyślnie dodany do kampani!
        </Alert>
      )}
      <Select
        name="type"
        label="Typ*"
        options={[
          ["Kampania po rozmowie #pozytywny", "x_campaign_after_call"],
          ["Kampania dla zainteresowanych w przyszłości", "x_campaign_future_partner"],
        ]}
        error={errors.type}
        value={watch("type")}
        rhf={register("type", {
          required: true,
        })}
        marginBottom="S"
      />
      <Input
        name="email"
        label="E-mail*"
        error={errors.email}
        rhf={register("email", {
          required: true,
          pattern: {
            value: EMAIL_REGEX,
            message: "Niepoprawny format e-mail",
          },
        })}
        marginBottom="S"
      />
      <Button marginLeft="auto" icon="send" loading={loading} onClick={handleSubmit(onSubmit)}>
        Wyślij
      </Button>
    </Accordion>
  )
}

export default ToolCampaign
